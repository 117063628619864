<script setup></script>

<template>
  <div class="auth-layout">
    <div class="auth-layout-content">
      <div class="auth-header">
        <img
          height="130"
          src="https://cdn.itemsatis.com/global/logo/original/zeminsiz3.png"
        />
      </div>
      <div class="auth-body">
        <h2 class="is-heading">{{ $route.meta.title }}</h2>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.auth-layout {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
  .auth-layout-content {
    min-width: 520px;
    border-radius: 10px;
    padding: 1.25rem;
    background-color: var(--secondary-background-color);
    .auth-header {
      display: flex;
      justify-content: center;
    }
    .auth-body {
      margin-top: 0.75rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .auth-layout .auth-layout-content {
    min-width: 390px;
  }
}

@media screen and (max-width: 575px) {
  .auth-layout .auth-layout-content {
    width: 90%;
  }
}
</style>
